@include font-face(100, 'Akrobat');
@include font-face(200, 'Akrobat');
@include font-face(300, 'Akrobat');
@include font-face(400, 'Akrobat');
@include font-face(600, 'Akrobat');
@include font-face(700, 'Akrobat');
@include font-face(800, 'Akrobat');
@include font-face(900, 'Akrobat');
@include font-face(300, 'Ubuntu');
@include font-face(400, 'Ubuntu');
@include font-face(500, 'Ubuntu');
@include font-face(700, 'Ubuntu');
@include font-face(300, 'UbuntuItalic');
@include font-face(400, 'UbuntuItalic');
@include font-face(500, 'UbuntuItalic');
@include font-face(700, 'UbuntuItalic');